<template>
  <div class="container">
    <div class="top">
      <div class="testsvgBg"></div>
      <div class="content">
        <div class="info">
          <p class="presstext">Press</p>
          <p class="subtext">
            For press and influencer inquiries, please email
          </p>
          <p class="email">
            <a
              class="email"
              :href="'mailto:' + 'press@litelypress.com'"
              target="_blank"
            >
              press@litelypress.com
            </a>
            or
            <a
              class="email"
              :href="'mailto:' + 'press@litelypr.com'"
              target="_blank"
              >press@litelypr.com</a
            >
          </p>
          <p class="subtext subTop">Please download our press kit here:</p>
          <a
            href="https://drive.google.com/drive/folders/1tfCsBT12KrvBdCPP3zco_IexJ3AToOj0?usp=sharing 
"
            target="_blank"
          >
            <p class="email">Download Press Kit</p>
          </a>
        </div>
        <div class="info"></div>
        <img class="image" src="https://media.litelyapp.com/web/ios.webp" />
      </div>
    </div>
    <div class="materials">
      <img src="@/assets/img/PressKit.webp" alt="" class="pressicon" />
      <div class="right_info">
        <p class="title">Press Kit</p>
        <a
          class="press_btn"
          href="https://drive.google.com/drive/folders/1tfCsBT12KrvBdCPP3zco_IexJ3AToOj0?usp=sharing"
          target="_blank"
          >Download Press Kit</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$store.commit("setIsApp", false);
  },
  mounted() {},
  components: {},
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import "@/css/less_custom.less";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .top {
    // height: 510px;
    width: 100%;
    // background-color: darkcyan;
    margin-bottom: 200px;
    position: relative;

    .testsvgBg {
      -webkit-mask-size: cover;
      mask-size: cover;
      width: 100%;
      height: 510px;
      background: linear-gradient(to right, #ffa164, #ff646b);
      -webkit-mask: url(../assets/svg/test.svg) no-repeat bottom;
      mask: url(../assets/svg/test.svg) no-repeat bottom;
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      //   background-color: red;
      position: absolute;
      top: 75px;
      width: 100%;

      .info {
        margin-right: 5%;
        margin-left: 5%;
        margin-bottom: 180px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .presstext {
          font-size: 81px;
          font-weight: 700;
          color: rgba(255, 255, 255, 1);
        }

        .subtext,
        .email {
          font-size: 24px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
        }
        .subTop {
          margin-top: 20px;
        }
        a {
          border-bottom: 1px solid white;
        }
      }

      .image {
        width: 478px;
        height: 583px;
      }
    }
  }

  .materials {
    margin-top: 50px;
    // width: 100px;
    // height: 200px;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 96px;

    .pressicon {
      width: 20vw;
      height: 20vw;
      margin-right: 60px;
    }

    .right_info {
      //   width: 200px;
      //   height: 100px;
      //   background-color: rosybrown;
      .title {
        font-size: 32px;
        font-weight: 700;
        color: rgba(23, 24, 27, 1);
        margin-bottom: 10px;
      }

      .press_btn {
        width: 320px;
        height: 70px;
        background-color: rgba(255, 191, 69, 1);
        border-radius: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        color: rgba(23, 24, 27, 1);
      }
    }
  }
}

@media screen and (max-width: @MobileMaxWidth) {
  .container {
    .top {
      margin-bottom: 50px;

      .testsvgBg {
        height: 650px;
      }

      .content {
        flex-direction: column;
        justify-content: flex-start;

        .info {
          margin-bottom: 20px;

          .presstext {
            font-size: 40px;
          }

          .subtext,
          .email {
            font-size: 24px;
            text-align: left;
          }
        }

        .image {
          width: 278px;
          height: 383px;
        }
      }
    }

    .materials {
      margin-top: 0px;
      flex-direction: column;
      justify-content: flex-start;

      // background-color: lightblue;
      .pressicon {
        width: 200px;
        height: 200px;
        margin-right: 0px;
      }

      .right_info .title {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 24px;
      }

      .right_info .press_btn {
        height: 60px;
        width: 280px;
        font-size: 18px;
      }
    }
  }
}
</style>
